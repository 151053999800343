import React, { Component } from 'react';
import { connect } from "react-redux";

import Select from 'react-select';
import settings from '../configs/settings';
import * as helper_family_tree from '../helpers/helper-family-tree';
import { sibling } from '../model-states/m-family-tree';
import helper_family_api from '../helpers/helper-family-api';
import { radix_bases } from '../helpers/helper-number-bases';
import { cloneDeep } from 'lodash'
import * as model from '../model-states/m-family-tree'
import ActivityIndicator from '../components/activity-indicator'
import ErrorSummary from '../components/error-summary';
import family_api from '../api/family-api'
import helper from '../helpers/index'

class ModalHalfSiblingsUnclesAunts extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sibling: null,
      proband_grandfather: {},
      proband_grandmother: {},
      loading: false,
      errorMessages: []
    };
  }

  componentDidMount() {
    try{
      let profiles = Object.values(this.props.profiles);
      let proband_father = profiles.find(profile => profile.id === this.props.patient.patient.father_id)
      let proband_mother = profiles.find(profile => profile.id === this.props.patient.patient.mother_id)

      let paternal_grandfather = profiles.find(profile => profile.id === proband_father.father_id)
      let paternal_grandmother = profiles.find(profile => profile.id === proband_father.mother_id)

      let maternal_grandfather = profiles.find(profile => profile.id === proband_mother.father_id)
      let maternal_grandmother = profiles.find(profile => profile.id === proband_mother.mother_id)

      let father = null;
      let mother = null;


      if(this.props.parent_side == 'paternal'){
        father = paternal_grandfather
        mother = paternal_grandmother
      }
      else{
        father = maternal_grandfather
        mother = maternal_grandmother
      }


      let sibling = cloneDeep(this.props.sibling)
  
      let half_sibling_shared_parent = null;
      let half_sibling_other_parent = null;

  
      // If half sibling, then set the shared parent, and other parent
      if ( sibling.father_id != father.id || sibling.mother_id != mother.id) {
        half_sibling_shared_parent = (sibling.father_id == father.id) ? 'father' : 'mother';
        let spouse = (sibling.father_id == father.id) ? father : mother;
        var partners = this.getParentOtherPartners(spouse.id, father, mother);
        if(partners.length > 0) {
          var other_parent_id = (half_sibling_shared_parent == 'father') ? sibling.mother_id : sibling.father_id;
          var other_parent = partners.find(partner => partner.id == other_parent_id);
          half_sibling_other_parent = (typeof(other_parent) !== 'undefined') ? other_parent : null;
        }
      }

      sibling.half_sibling_shared_parent = half_sibling_shared_parent
      sibling.half_sibling_other_parent = half_sibling_other_parent

      this.setState({sibling: sibling, proband_grandmother: mother, proband_grandfather: father})

    }
    catch(error){
      console.log(error)
    }
  }

  onClickSave() {
    this.saveSibling();
  }

  onChange(field, value) {
    let sibling = Object.assign({}, this.state.sibling, {[field]: value});
    this.setState({sibling});
  }

  twinCheck() {
    this.props.isTwin ? this.props.onTwins() : this.onClickSave()
  }

  async saveSibling() {
    try {

      let profiles = Object.values(this.props.profiles);
      let father = profiles.find(profile => profile.id === this.state.sibling.father_id)
      let mother = profiles.find(profile => profile.id === this.state.sibling.mother_id)
      let shared_parent = this.state.sibling.half_sibling_shared_parent
      let other_parent = this.state.sibling.half_sibling_other_parent

      let proband_grandfather = this.state.proband_grandfather
      let proband_grandmother = this.state.proband_grandmother

      var spouse = (shared_parent == 'father') ? father : mother;

      let both_null = (shared_parent === null && other_parent === null)
      let both_set = (shared_parent && other_parent)

      // If only one has value, then enforce validation
      if(!both_null  && !both_set) {
        let message = (this.state.sibling.half_sibling_shared_parent == null) ? 'Shared parent is required' : 'Other parent is required';
        throw new Error (message)
      }

      this.setState({loading: true})

      await this.updateSiblingName();

      if(both_set)
      {
        // Put to new parent (this is half sibling)
        var partner = (both_set === 'new_partner') ? await this.createNewPartner() : both_set;
        await this.updateSiblingParent(this.state.sibling, spouse, partner, true)

      } else {
        // Put back under proband parent (this is now full sibling)
        await this.updateSiblingParent(this.state.sibling, proband_grandfather, proband_grandmother, false)
      }
      
      this.props.onClose();

    } catch (error) {
      this.setState({errorMessages: [error.message]})
      helper.logger(error)
    } finally {
      this.setState({loading: false})
    }

  }

  async updateSiblingName() {
    try {
      await family_api.patch_member_memberid(this.state.sibling.id, {
        first_name: this.state.sibling.first_name
      })
      helper_family_tree.saveProfileToRedux(this.props.dispatch, this.state.sibling)
      helper_family_tree.saveUncleAuntDetailToRedux(this.props.dispatch, this.props.parent_side, this.state.sibling);
    } catch (error) {
      throw error
    }
  }

  getParentOtherPartners(half_sibling_shared_parent, father, mother) {
    let partners = this.props.patient.partners
    var ownerRkey = 'apimem-' + half_sibling_shared_parent; // parent_father or parent_mother
    if(ownerRkey in partners) {
      var partners_shared_parent = partners[ownerRkey]
      partners_shared_parent = partners_shared_parent.filter(partner => !partner.is_blood_related_to_proband)
      return partners_shared_parent;
    }

    return []
  }

  async createNewPartner() {

    try {
      let profiles = Object.values(this.props.profiles);
      let father = profiles.find(profile => profile.id === this.state.sibling.father_id)
      let mother = profiles.find(profile => profile.id === this.state.sibling.mother_id)
      let shared_parent = this.state.sibling.half_sibling_shared_parent
      let other_parent = this.state.sibling.half_sibling_other_parent
      

      var spouse = (shared_parent == 'father') ? father : mother;
      var ownerRkey = 'apimem-' + spouse.id;
      var partner = model.createPartner()
      var data = await helper_family_api.create_partner(spouse, partner, 'both')
      partner = Object.assign({}, partner, data)

      helper_family_tree.savePartnerToRedux(this.props.dispatch, ownerRkey, partner)
      helper_family_tree.saveProfileToRedux(this.props.dispatch, partner)
      return partner

    } catch (error) {
      throw error
    }

  }

  async updateSiblingParent(sibling, spouse, partner, half) {
    try {

      let father_id = spouse.id;
      let mother_id = partner.id;

      if(spouse.gender.toLowerCase() == 'f') {
        father_id = partner.id;
        mother_id = spouse.id;
      }

      var parent = await family_api.post_childs({
        father_id: father_id,
        mother_id: mother_id,
        member_id: sibling.id,
      })

      // Update Sibling Father and Mother ID
      let cloneSibling = cloneDeep(sibling)
      cloneSibling.father_id = father_id;
      cloneSibling.mother_id = mother_id;
      cloneSibling.half = half;
      this.setState({sibling: cloneSibling})
      helper_family_tree.saveProfileToRedux(this.props.dispatch, cloneSibling)
      helper_family_tree.saveUncleAuntDetailToRedux(this.props.dispatch, this.props.parent_side, cloneSibling);

    } catch (error) {
      throw error
    }
  }

  getOtherParentOptions(parent) {
    let other_partner_options = [];
    if (parent) {
      var side  = (this.props.parent_side == 'paternal') ? 'father' : 'mother';
      var side_profile = this.props.patient.patient[side];
      var parent_id = side_profile[(parent == 'father') ? 'father_id' : 'mother_id'];
      var partners = this.props.patient.partners[`apimem-${parent_id}`];
      if (!partners) {
        other_partner_options.push({ value: 'new_partner', label: 'Add New Partner...' })
        return other_partner_options
      } else {
        partners = partners.filter(partner => !partner.is_blood_related_to_proband);
      }
      partners.forEach((partner, index) => {
        other_partner_options.push({
          value: partner,
          label: partner.first_name ? partner.first_name : `Partner #${index + 1}`
        });
      });
    }

    other_partner_options.push({ value: 'new_partner', label: 'Add New Partner...' })
    return other_partner_options
  }

  render() {
    if(this.state.sibling == null) return <React.Fragment></React.Fragment>
    let half_sibling_shared_parent = helper.getSelectedOption(settings.half_siblings_shared_parent_options, this.state.sibling.half_sibling_shared_parent);

    let other_partner_options = this.getOtherParentOptions(half_sibling_shared_parent?.value);
    let half_sibling_other_parent = helper.getSelectedOption(other_partner_options, this.state.sibling.half_sibling_other_parent);
    
    return (
      <div role="dialog"
        onClick={() => this.props.onClose()}
        style={{ zIndex: 9998, display: ((this.props.isOpen) ? 'block' : 'none') }}
        className={"modal fade " + ((this.props.isOpen) ? 'in' : '')}>

        <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Half-Sibling</h4>
            </div>

            <div className="modal-body">

            <ErrorSummary errorMessages={this.state.errorMessages} />

                  <div className="row">
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>{this.props.label}</label>
                        <input
                          value={this.state.sibling.first_name}
                          onChange={(e) => this.onChange('first_name', e.target.value)}
                          type="text" className="form-control" placeholder="First Name" />
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <label>Shared Parent</label>
                      <div className="custom-select">
                        <Select
                          value={half_sibling_shared_parent}
                          onChange={(item) => {
                            let value = item ? item.value : null
                            this.onChange('half_sibling_shared_parent', value)
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={settings.half_siblings_shared_parent_options}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <label>Other Parent</label>
                      <div className="custom-select">
                        <Select
                          value={half_sibling_other_parent}
                          onChange={(item) => {
                            let value = item ? item.value : null
                            this.onChange('half_sibling_other_parent', value)
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={other_partner_options}
                          isDisabled={!half_sibling_shared_parent}
                        />
                      </div>
                    </div>
                  </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                className="btn btn-light-outline no-margin-right">Cancel</button>
              <button
                onClick={() => this.twinCheck()}
                className="btn btn-dark">Save</button>

              <ActivityIndicator
                modal={true}
                loading={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const redux_state = state => ({
  profiles: state.patient.profile,
  patient: state.patient,
  clinician_id: state.session.user.clinician_id,
  dial_code: state.session.user.dial_code
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(ModalHalfSiblingsUnclesAunts);
