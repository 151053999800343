import React, { useEffect, useState, useRef } from "react";
import PrintDropdowns from './print-drop-downs';
//@ts-ignore
import {Checkbox} from 'react-icheck';
import PrintCheckBoxes from "./print-checkboxes";
//@ts-ignore
import Cookie from 'js-cookie';
import {
  CookieKeys,
} from '../react-flow-pedigree/pedigree-constants';

interface PrintSettingsProps {
    closeModal: () => void;
    printPedigree:(print:any, settings: any) => void;
    svgForPrint: any;
    updateSvgForPrint: (option: PrintSettings) => void;
    deIdentify: boolean;
    blackAndWhite: boolean;
}

interface PrintSettings{
  orientation: string;
  pages?: string;
  options?: string;
  identify?: boolean;
  blackWhite?: boolean;
}

const printOrientation = [
  { label: 'Portrait', value: 'Portrait' },
  { label: 'Landscape', value: 'Landscape' }
];

const printOptions = [
  { label: 'PDF', value: 'pdf' },
  { label: 'PNG', value: 'image' }
]

const printPages = [
  { label: 'Single', value: 'FitToPage' },
  { label: 'Multiple', value: 'FitToOnePageTall' }
]

const ModalPrintSettings: React.FC<PrintSettingsProps> = ({closeModal, printPedigree, updateSvgForPrint, svgForPrint}) =>{
    const [transform, setTransform] = useState(0);
    const [printSettings, setPrintSettings] = useState<PrintSettings>
    (
      { orientation: 'Portrait', pages: 'FitToPage', options: 'pdf', identify: false, blackWhite: false }
    );

    const [disabledOptions, setDisabledOptions] = useState(false);
  
  
    useEffect(() => { 
      const displayBlackAndWhite = Cookie.get(CookieKeys.PEDIGREE_BLACK_AND_WHITE);
      const viewStatus = Cookie.get(CookieKeys.PEDIGREE_VIEW_STATUS);
      const blackWhite = displayBlackAndWhite === 'true' ? true : false;
      const identify = viewStatus === 'deidentified' ? true : false;
      if(blackWhite) updatePrintSetting('blackWhite', blackWhite);
      if(identify) updatePrintSetting('identify', identify);
    }, []);



  const updatePrintSetting = (key: string, value: string | boolean) => {
    setPrintSettings(prevSettings => {
      const newSettings = {...prevSettings, [key]: value};
      updateSvgForPrint(newSettings);
      return newSettings;
    });  
  }

  const updateOrientation = (orientation: any) => {

    let value = orientation.value || 'Portrait';
    if (value === 'Portrait') setTransform(0);
    else if(value === 'Landscape') setTransform(90);
    updatePrintSetting('orientation', orientation)

  }

  const updateFormat = (options: {Label: string, value: string}) => {
    if(options.value === 'image') setDisabledOptions(true)
    else setDisabledOptions(false)
    updatePrintSetting('options', options.value)
  }

  const updatePages = (pages: any) => {
    updatePrintSetting('pages', pages.value)
  }

  const SVGExample = () => {
    const { height = 750, width = 1000, header = '', footer = '', svgData } = svgForPrint;
    
    const cleanSVG = (svg: string) => svg.replace(/^"(.*)"$/, '$1').replace(/<svg[^>]*>/, '').replace(/<\/svg>/, '');

    const svgDisplay = svgData ? cleanSVG(svgData) : '';
    const headerSVGDisplay = header ? cleanSVG(header) : '';
    const footerSVGDisplay = footer ? cleanSVG(footer) : '';
    const customSVGHeader = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 ${width} ${height}">${footerSVGDisplay}${headerSVGDisplay}${svgDisplay}</svg>`;
    const displaySVG = customSVGHeader.replace(/^"(.*)`$/, '$1');


    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
        }}
        dangerouslySetInnerHTML={{ __html: displaySVG }}
      />
    );
  };

  function print() {
    printPedigree(svgForPrint, printSettings);
  }
  const format = printOptions.find( item => printSettings.options === item.value)
  const orientation = printOrientation.find( item => printSettings.orientation === item.value)
  const pages = printPages.find( item => printSettings.pages === item.value)

  return (
    <div
      className="modal fade in" 
      role="dialog"
      style={{ zIndex: 9999, display: "block", paddingTop: '25px', backgroundColor: 'transparent'}}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="modal-dialog modal-lg"
        role="document"
      >
        <div className="modal-content">
            <div className="modal-header">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                >
                    <i onClick={closeModal}className="fa fa-close" />
                </button>
                <h4 className="modal-title text-capitalize">
                    Save As PDF / Image
                </h4>
            </div>


          <div id='family-history-modal' className="modal-body pedigree-print-settings">

            <div className="print-options">
                <div className="row">
                    <PrintDropdowns
                      label="Format"
                      options={printOptions}
                      selectedValue={format}
                      onSelect={updateFormat}
                    />
                  </div>
                <div className="row ">
                    <PrintDropdowns
                      label="Orientation"
                      options={printOrientation}
                      selectedValue={orientation}
                      onSelect={updateOrientation}
                      disabled={disabledOptions}
                    />
                  </div>

                  <div className="row">
                    <PrintDropdowns
                      label="Pages"
                      options={printPages}
                      selectedValue={pages}
                      onSelect={updatePages}
                      disabled={disabledOptions}

                    />
                  </div>
              
                  <div className="row">
                    <PrintCheckBoxes
                      updateBlackWhite={(blackWhite: boolean) => 
                        updatePrintSetting('blackWhite', blackWhite)
                      }
                      updateDeIdentify={(identify: boolean) => 
                        updatePrintSetting('identify', identify)
                      }
                      identify={printSettings.identify ? printSettings.identify : false}
                      blackWhite={printSettings.blackWhite ? printSettings.blackWhite : false} 
                    />
                  </div>
                </div>
              <div className="paper-container" style={{'transform': `rotate(${transform}deg)`}}>
                <div className="paper portrait">
                    <div className="svg-wrapper">
                    {SVGExample()}
                  </div>
                </div> 
              </div>
    
        </div>
        
        <div className="modal-footer">
          <div style={{textAlign: 'right'}}>
                <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-light-outline "
                    style={{ marginRight: 5 }}
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark "
                    data-toggle="modal"
                    data-dismiss="modal"
                    style={{ marginRight: 5}}
                    onClick={print}
                  >
                  Continue
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ModalPrintSettings;
